export const WHAT_WE_DO_CARDS = [
	{
		title: 'Technology',
		description:
			"LRC's advanced technology allows us to operate efficiently and provide a smooth experience to landlords and tenants alike.",
	},
	{
		title: 'Scale',
		description:
			'LRC deploys significant capital to achieve scalable investment strategies, focusing on fast growth in key cities.',
	},
	{
		title: 'Sustainability',
		description:
			'LRC is committed to supporting sustainable practices that generate positive environmental and social impacts.',
	},
	{
		title: 'Presence',
		description: 'Extensive experience across Pan-European jurisdictions',
	},
];
