import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import styles from './ExpertiseSkill.module.scss';
import useOnScreen from '../../../hooks/useOnScreen';
export default function ExpertiseSkill() {
	const ref = useRef(null);

	const [reveal, setReveal] = useState(false);
	const onScreen = useOnScreen(ref);

	useEffect(() => {
		if (onScreen) setReveal(onScreen);
	}, [onScreen]);
	return (
		<div data-scroll-section>
			<section className='container'>
				<div className={styles.ExpertiseSkillAlignment}>
					<div className={styles.grid}>
						<div className={styles.gridItems}>
							<h1
								className='c-header_title o-h1'
								data-scroll
								data-scroll-speed='1'
								data-scroll-repeat
							>
								<span
									className={classNames('c-header_title_line', {
										'is-reveal': reveal,
									})}
								>
									<span
										data-scroll
										data-scroll-speed='1'
										data-scroll-repeat
										data-scroll-position='top'
									>
										LRC Group provides a diverse set of skills
									</span>
								</span>
							</h1>
						</div>
						<div
							className={styles.gridItems}
							data-scroll
							data-scroll-speed='1'
						>
							<p>
								LRC invests across a wide range of asset classes, operating
								dedicated platforms enabling it to deliver to investors
								end-to-end services along the investment value chain from
								origination, asset and property management, accounting, finance
								and fund management. In addition to its strong focus on
								residential property, LRC has a significant track record in
								commercial real estate and the hospitality sector.
							</p>
							<p>
								LRC Group’s investment portfolios are geographically diversified
								yet strategically concentrated in high-potential target cities.
								Through granular asset aggregation strategies, LRC
								systematically builds institutional-grade portfolios at scale,
								optimizing portfolio composition to maximize investor returns
								upon exit.
							</p>
							<p>
								Committed to enhancing long-term value, LRC proactively
								identifies and leverages planning opportunities, ensuring that
								each asset is strategically positioned for growth, operational
								efficiency, and value creation.
							</p>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}
