import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import styles from './OurApproach.module.scss';
import useOnScreen from '../../../hooks/useOnScreen';
export default function OurApproach() {
	const ref = useRef(null);

	const [reveal, setReveal] = useState(false);
	const onScreen = useOnScreen(ref);

	useEffect(() => {
		if (onScreen) setReveal(onScreen);
	}, [onScreen]);
	return (
		<div data-scroll-section>
			<section className={styles.OurApproachAlignment}>
				<div className='container'>
					<div className={styles.grid}>
						<div className={styles.gridItems}>
							<h1>
								A Leading Pan-European Real Estate Investment Firm & Fund
								Manager
							</h1>
						</div>
						<div className={styles.gridItems}>
							<h6>LRC’s approach</h6>
						</div>
					</div>
					<div className={styles.subTextGrid}>
						<div className={styles.subTextGridItems}>
							<p>
								LRC Group is a privately held real estate investment and fund
								management firm with a strong track record across Pan-European
								markets. With extensive experience in acquiring, operating, and
								managing assets, LRC structures its investments through
								regulated funds and JV platforms, leveraging in-house asset and
								property management expertise to drive efficiency and value
								creation.
							</p>
						</div>
						<div className={styles.subTextGridItems}>
							<p>
								LRC strategically invests in markets with strong demographic and
								economic fundamentals, with a significant presence in the UK,
								Ireland, Germany, and Poland. Its flexible, opportunity-driven
								approach allows it to assess investments based on individual
								merits, without restrictions on asset class, volume, or
								jurisdiction. By combining strategic asset management with a
								focus on long-term growth, LRC continues to enhance its
								diversified portfolio across Europe.
							</p>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}
