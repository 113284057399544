import React, { useState } from 'react';
import styles from './Header.module.scss';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import Logo from '../../Assets/Logo/logo_svg.svg';
import MobileMenu from '../../Assets/Images/mobile-menu.svg';
import MobileLogo from '../../Assets/Logo/mobile-logo.svg';
import MinimizeIcon from '../../Assets/Images/final-close.svg';
import { MAIN_LINKS } from '../../constants/links';

export default function Header() {
	const [mobileHeader, setMobileHeader] = useState(false);

	return (
		<div>
			<div className='long-container'>
				<div className={styles.headerAlignment}>
					<NavLink to='/'>
						<div className={styles.logo}>
							<img
								src={Logo}
								alt='Logo'
								className='flipImage'
								data-scroll
								data-scroll-repeat
							/>
						</div>
					</NavLink>
					<div className={styles.Menu}>
						<nav>
							<ul>
								{MAIN_LINKS.map(item => (
									<li key={item.href + 'header-des'}>
										<span>
											<NavLink to={item.href}>{item.title}</NavLink>
										</span>
										<span>
											<NavLink to={item.href}>{item.title}</NavLink>
										</span>
									</li>
								))}
							</ul>
						</nav>
					</div>
					<div
						className={styles.mobileMenu}
						onClick={() => setMobileHeader(!mobileHeader)}
					>
						<img
							src={MobileMenu}
							alt='MobileMenu'
						/>
					</div>
				</div>
			</div>
			<div
				className={
					mobileHeader
						? classNames(styles.mobileHeader, styles.mobileHeaderShow)
						: classNames(styles.mobileHeader, styles.mobileHeaderHidden)
				}
			>
				<div className={styles.mobileHeaderDesign}>
					<div className={styles.logoIconAlignment}>
						<NavLink to='/'>
							<img
								src={MobileLogo}
								alt='MobileLogo'
							/>
						</NavLink>
						{/* <img className={styles.mobileCloseImageSize} src={MinimizeIcon} alt="MinimizeIcon" onClick={() => setMobileHeader(false)} /> */}
						<div
							className={styles.mobileCloseIconAlignment}
							onClick={() => setMobileHeader(false)}
						>
							<img
								src={MinimizeIcon}
								alt='MinimizeIcon'
							/>
						</div>
					</div>
					<div className={styles.mobileMenu}>
						<ul>
							{MAIN_LINKS.map(item => (
								<li key={item.href + 'navbar-mob'}>
									<span>
										<NavLink to={item.href}>{item.title}</NavLink>
									</span>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}
