import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import BlackHeader from '../Layout/BlackHeader/BlackHeader';
import UrbenBanner from '../../Assets/esg-page/hero.jpg';
import styles from './UrbanRegenration.module.scss';
import GeneratingInterest from './GeneratingInterest/GeneratingInterest';
import TechnologySection from './TechnologySection/TechnologySection';
import Footer from '../Layout/Footer/Footer';
import useLocoScroll from '../../hooks/useLocoScroll';
import useOnScreen from '../../hooks/useOnScreen';
import MetaDecorator from '../Util/MetaDecorator';
export default function UrbanRegenration() {
	useLocoScroll(true);
	const ref = useRef(null);

	const [reveal, setReveal] = useState(false);
	const onScreen = useOnScreen(ref);

	useEffect(() => {
		if (onScreen) setReveal(onScreen);
	}, [onScreen]);
	return (
		<>
			<div className='sticky-header'>
				<BlackHeader />
			</div>
			<div
				id='main-container'
				data-scroll-container
			>
				<MetaDecorator
					title={'LRC Group | Urban Regeneration'}
					description={
						'LRC’s focus is on real estate impact investments in areas of strong demand and low supply. The Group keeps ahead of the curve by transforming under-utilised assets as part of a conscientious urban renewal process. Alongside driving profitability, LRC makes a significant and positive impact on local communities.'
					}
				/>
				<div
					className={styles.UrbanRegenrationPageTitle}
					data-scroll-section
				>
					<div className='long-container'>
						<div className={styles.pageTitle}>
							<h1
								className='c-header_title o-h1'
								data-scroll
								data-scroll-speed='3'
								data-scroll-position='top'
								data-scroll-repeat
							>
								<span
									className={classNames('c-header_title_line', {
										'is-reveal': reveal,
									})}
								>
									<span
										className='italic-class'
										data-scroll
										data-scroll-speed='3'
										data-scroll-position='top'
										data-scroll-repeat
									>
										Integrating{' '}
										<b>Environmental, Social, and Governance principles</b> into
										every aspect of our business
									</span>
								</span>
							</h1>
						</div>
					</div>
				</div>
				<section
					className={classNames(styles.urbanSpaceAlignment, 'long-container')}
					data-scroll-section
				>
					<div className={styles.UrbanRegenrationBanner}>
						<img
							src={UrbenBanner}
							alt='UrbenBanner'
							className='flipImage'
							data-scroll
							data-scroll-repeat
						/>
					</div>
				</section>
				<>
					<GeneratingInterest />
				</>
				<>
					<TechnologySection />
				</>
				{/* <>
        <PlaceratTellus/>
      </> */}
				<>
					<Footer />
				</>
				<div className='footerBottomAlignment'></div>
			</div>
		</>
	);
}
