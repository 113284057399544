import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import useOnScreen from '../../../hooks/useOnScreen';
import styles from './TechnologySection.module.scss';
import governance from '../../../Assets/esg-page/governence.jpg';

import CommunitiesImageNew from '../../../Assets/esg-page/community.jpg';
// import sustainibility from '../../../Assets/esg-page/sustainibility.jpg';
import social from '../../../Assets/esg-page/social.jpg';
import { NavLink, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { footerBottomLinks, secondaryLinks } from '../../../constants/links';
export default function TechnologySection() {
	const history = useHistory();
	const ref = useRef(null);

	const [reveal, setReveal] = useState(false);
	const onScreen = useOnScreen(ref);
	const isRouteHandler = route => {
		history.push(`/${route}`);
	};
	useEffect(() => {
		if (onScreen) setReveal(onScreen);
	}, [onScreen]);
	return (
		<div data-scroll-section>
			{/* <section
				className={classNames(
					styles.communtiesAlignment,
					styles.bottomSpaceRemoveSection
				)}
			>
				<div className={styles.TechnologySectionAlignment}>
					<div className='container'>
						<div className={styles.grid}>
							<div className={styles.gridItems}>
								
								<h3
									className='c-header_title o-h1'
									data-scroll
									data-scroll-speed='1'
									data-scroll-repeat
								>
									<span
										className={classNames('c-header_title_line', {
											'is-reveal': reveal,
										})}
									>
										<span
											data-scroll
											data-scroll-speed='1'
											data-scroll-repeat
											data-scroll-position='top'
										>
											Sustainability is at the heart of our real estate
											strategy.
										</span>
									</span>
								</h3>
							</div>
							<div className={styles.gridItems}>
								<div className={styles.trasnformingBox}>
									<h1
										className='c-header_title o-h1'
										data-scroll
										data-scroll-speed='1'
										data-scroll-repeat
									>
										<span
											className={classNames('c-header_title_line', {
												'is-reveal': reveal,
											})}
										>
											<span
												data-scroll
												data-scroll-speed='1'
												data-scroll-repeat
												data-scroll-position='top'
											>
												SUSTAINABILITY
											</span>
										</span>
									</h1>
									<div
										data-scroll
										data-scroll-speed='1'
									>
										<h2>Environmental Responsibility</h2>
										<p>
											We are committed to reducing the environmental impact of
											our portfolio and promoting sustainable practices across
											our operations.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div>
						<div className='container'>
							<div className={styles.transformingImage}>
								<img
									src={sustainibility}
									alt='CommunitiesImage'
									className='flipImage'
									data-scroll
									data-scroll-repeat
								/>
							</div>
						</div>
					</div>
					<div className={styles.mobileTextShow}>
						<h3
							className='c-header_title o-h1'
							data-scroll
							data-scroll-speed='1'
							data-scroll-repeat
						>
							<span
								className={classNames('c-header_title_line', {
									'is-reveal': reveal,
								})}
							>
								<span
									data-scroll
									data-scroll-speed='1'
									data-scroll-repeat
									data-scroll-position='top'
								>
									Sustainability is at the heart of our real estate strategy.
								</span>
							</span>
						</h3>
					</div>
				</div>
			</section> */}

			<section className={styles.communtiesAlignment}>
				<div className={styles.TechnologySectionAlignment}>
					<div className='container'>
						<div className={styles.gridChildSec}>
							<div className={styles.gridItems}>
								<div className={styles.trasnformingBox}>
									<h1
										className='c-header_title o-h1'
										data-scroll
										data-scroll-speed='1'
										data-scroll-repeat
									>
										<span
											className={classNames('c-header_title_line', {
												'is-reveal': reveal,
											})}
										>
											<span
												data-scroll
												data-scroll-speed='1'
												data-scroll-repeat
												data-scroll-position='top'
											>
												SOCIAL IMPACT
											</span>
										</span>
									</h1>
									<div
										data-scroll
										data-scroll-speed='1'
									>
										<h2>Our focus areas include:</h2>
										<ul style={{ paddingLeft: '20px' }}>
											<li>
												Tenant Experience: Offering high-quality, safe, and
												flexible spaces tailored to meet the needs of modern
												tenants.
											</li>
											<li>
												Employee Well-Being: Promoting a diverse and inclusive
												workplace culture where employees can thrive through
												professional development opportunities and well-being
												initiatives.
											</li>
											<li>
												Affordable Mid-Market Housing Solutions: Contributing to
												housing solutions that address societal challenges and
												create accessible living spaces.
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div className={styles.gridItems}>
								
								<h3
									className='c-header_title o-h1'
									data-scroll
									data-scroll-speed='1'
									data-scroll-repeat
								>
									<span
										className={classNames('c-header_title_line', {
											'is-reveal': reveal,
										})}
									>
										<span
											data-scroll
											data-scroll-speed='1'
											data-scroll-repeat
											data-scroll-position='top'
										>
											At LRC Group, we strive to create positive social outcomes
											by fostering inclusivity, community engagement, and
											well-being.
										</span>
									</span>
								</h3>
							</div>
						</div>
					</div>
					<div>
						<div className='container'>
							<div className={styles.transformingImageleftAlign}>
								<img
									src={social}
									alt='CommunitiesImage'
									className='flipImage'
									data-scroll
									data-scroll-repeat
								/>
							</div>
						</div>
					</div>
					<div className={styles.mobileTextShow}>
						<h3
							className='c-header_title o-h1'
							data-scroll
							data-scroll-speed='1'
							data-scroll-repeat
						>
							<span
								className={classNames('c-header_title_line', {
									'is-reveal': reveal,
								})}
							>
								<span
									data-scroll
									data-scroll-speed='1'
									data-scroll-repeat
									data-scroll-position='top'
								>
									At LRC Group, we strive to create positive social outcomes by
									fostering inclusivity, community engagement, and well-being.
								</span>
							</span>
						</h3>
					</div>
				</div>
			</section>

			<section className={styles.TechnologySectionAlignment}>
				<div className='container'>
					<div className={styles.grid}>
						<div className={styles.gridItems}>
							
							<h3
								className='c-header_title o-h1'
								data-scroll
								data-scroll-speed='1'
								data-scroll-repeat
							>
								<span
									className={classNames('c-header_title_line', {
										'is-reveal': reveal,
									})}
								>
									<span
										data-scroll
										data-scroll-speed='1'
										data-scroll-repeat
										data-scroll-position='top'
									>
										Transparency, accountability, and ethical decision-making
										are the cornerstones of our governance framework.
									</span>
								</span>
							</h3>
						</div>
						<div className={styles.gridItems}>
							<div className={styles.trasnformingBox}>
								<h1
									className='c-header_title o-h1'
									data-scroll
									data-scroll-speed='1'
									data-scroll-repeat
								>
									<span
										className={classNames('c-header_title_line', {
											'is-reveal': reveal,
										})}
									>
										<span
											data-scroll
											data-scroll-speed='1'
											data-scroll-repeat
											data-scroll-position='top'
										>
											Governance
										</span>
									</span>
								</h1>
								<div
									data-scroll
									data-scroll-speed='1'
								>
									<h2>Governance Excellence</h2>
									<p>
										At LRC Group, we adhere to the highest standards to ensure
										long-term trust and integrity.
									</p>
									<p style={{ paddingTop: '10px' }}>
										Our governance efforts include:
									</p>
									<ul style={{ paddingTop: '10px', paddingLeft: '20px' }}>
										<li>
											Responsible Investment: Integrating ESG considerations
											into our investment decision-making process.
										</li>
										<li>
											Compliance and Ethics: Upholding rigorous compliance with
											all regulations and embedding strong ethical practices
											throughout our organization.
										</li>
										<li>
											Stakeholder Engagement: Maintaining open and transparent
											communication with our investors, tenants, and partners.
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section>
				<div className='container'>
					<div className={styles.transformingImage}>
						<img
							src={governance}
							alt='TransformingImage'
							className='flipImage'
							data-scroll
							data-scroll-repeat
						/>
					</div>
				</div>
				<div className={styles.mobileTextShow}>
					<h3
						className='c-header_title o-h1'
						data-scroll
						data-scroll-speed='1'
						data-scroll-repeat
					>
						<span
							className={classNames('c-header_title_line', {
								'is-reveal': reveal,
							})}
						>
							<span
								data-scroll
								data-scroll-speed='1'
								data-scroll-repeat
								data-scroll-position='top'
							>
								Transparency, accountability, and ethical decision-making are
								the cornerstones of our governance framework.
							</span>
						</span>
					</h3>
				</div>
			</section>
			<section className={styles.communtiesAlignment}>
				<div className={styles.TechnologySectionAlignment}>
					<div className='container'>
						<div
							className={classNames(
								styles.communitiesMobileViewAlignment,
								styles.gridChildSec
							)}
						>
							<div className={styles.gridItems}>
								<div className={styles.trasnformingBox}>
									<h1
										className='c-header_title o-h1'
										data-scroll
										data-scroll-speed='1'
										data-scroll-repeat
									>
										<span
											className={classNames('c-header_title_line', {
												'is-reveal': reveal,
											})}
										>
											<span
												data-scroll
												data-scroll-speed='1'
												data-scroll-repeat
												data-scroll-position='top'
											>
												Driving Positive Change Together
											</span>
										</span>
									</h1>
									<div
										data-scroll
										data-scroll-speed='1'
									>
										
										<p>
											Links:
											<a
												style={{
													padding: '10px 0px',
													textTransform: 'initial',
												}}
												href={footerBottomLinks.sfdrDisclosure}
											>
												SFDR Disclosure
											</a>
											<a
												style={{
													paddingBottom: '10px',
													textTransform: 'initial',
												}}
												href={footerBottomLinks.paiStatement}
											>
												PAI Statement
											</a>
										</p>
									</div>
								</div>
							</div>
							<div
								className={classNames(
									styles.communtiesMobileAlign,
									styles.gridItems
								)}
							>
								
								<h3
									className='c-header_title o-h1'
									data-scroll
									data-scroll-speed='1'
									data-scroll-repeat
								>
									<span
										className={classNames('c-header_title_line', {
											'is-reveal': reveal,
										})}
									>
										<span
											data-scroll
											data-scroll-speed='1'
											data-scroll-repeat
											data-scroll-position='top'
										>
											To learn more about our ESG strategy and initiatives, or
											to collaborate with us on creating a positive impact,
											please contact us.
										</span>
									</span>
								</h3>
							</div>
						</div>
					</div>
					<div>
						<div className='container'>
							<div className={styles.transformingImageleftAlign}>
								<img
									src={CommunitiesImageNew}
									alt='CommunitiesImageNew'
									className='flipImage'
									data-scroll
									data-scroll-repeat
								/>
							</div>
						</div>
					</div>
					<div className={styles.mobileTextShow}>
						<h3
							className='c-header_title o-h1'
							data-scroll
							data-scroll-speed='1'
							data-scroll-repeat
						>
							<span
								className={classNames('c-header_title_line', {
									'is-reveal': reveal,
								})}
							>
								<span
									data-scroll
									data-scroll-speed='1'
									data-scroll-repeat
									data-scroll-position='top'
								>
									LRC creates opportunities for tenants to
								</span>
								<span
									className='italic-class'
									data-scroll
									data-scroll-speed='1'
									data-scroll-position='top'
									data-scroll-repeat
								>
									{' '}
									connect, interact and create long-lasting relationships.
								</span>
							</span>
						</h3>
					</div>
				</div>
			</section>

			<div
				className={classNames(styles.mobileTextShowLast, styles.mobileTextShow)}
			>
				<h3
					className='c-header_title o-h1'
					data-scroll
					data-scroll-speed='1'
					data-scroll-repeat
				>
					<span
						className={classNames('c-header_title_line', {
							'is-reveal': reveal,
						})}
					>
						<span
							data-scroll
							data-scroll-speed='1'
							data-scroll-repeat
							data-scroll-position='top'
						>
							LRC partnered with Delos to promote the Stay Well™ program.
						</span>
					</span>
				</h3>
			</div>
		</div>
	);
}
