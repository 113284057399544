import React, { useEffect, useState, useRef } from 'react';
import styles from './MissionAndVision.module.scss';
import MissionImage from '../../../Assets/home-page-images/vision/2.jpg';
import VissionImage from '../../../Assets/home-page-images/vision/1.jpg';
import useOnScreen from '../../../hooks/useOnScreen';
export default function MissionAndVision() {
	const ref = useRef(null);

	const [reveal, setReveal] = useState(false);
	const onScreen = useOnScreen(ref);

	useEffect(() => {
		if (onScreen) setReveal(onScreen);
	}, [onScreen]);
	return (
		<div data-scroll-section>
			<section className={styles.missinNewAlignment}>
				<div className='container'>
					<div className={styles.newTextGrid}>
						<div className={styles.newTextGridItems}>
							<a>Introduction</a>
							<h1>
								The Group’s{' '}
								<span className='italic-class'>Mission & Vision</span>
							</h1>
						</div>
					</div>
					<div>
						<div className={styles.firstGrid}>
							<p>
								The LRC Group is an established Pan-European real estate private
								equity investment firm offering institutional investors fully
								integrated capabilities across origination, acquisition,
								property management, asset management and fund management.
							</p>
							<img
								src={VissionImage}
								alt='VissionImage'
								className='flipImage'
								data-scroll
								data-scroll-repeat
							/>
						</div>
						<div className={styles.secondGrid}>
							<img
								src={MissionImage}
								alt='MissionImage'
								className='flipImage'
								data-scroll
								data-scroll-repeat
							/>
							<p>
								LRC Group’s investment portfolios are geographically diversified
								yet strategically concentrated in high-potential target cities.
								Through granular asset aggregation strategies, LRC
								systematically builds institutional-grade portfolios at scale,
								optimizing portfolio composition to maximize investor returns
								upon exit.
							</p>
						</div>
					</div>
				</div>
			</section>
			{/* <section className={styles.mobileviewSectionShow}>
                <div className={styles.MobileViewImageFirst}>
                    <img src={VissionImage} alt="VissionImage" className="flipImage" data-scroll data-scroll-repeat />
                </div>
                <div className="container">
                    <div className={styles.fullImageBoxAlign}>
                        <img src={MissionImage} alt="MissionImage" className="flipImage" data-scroll data-scroll-repeat />
                    </div>
                </div>
            </section> */}
			<section className={styles.LogoBanner}>
				<div className={styles.Marquee}>
					<div className={styles.AlignmentContent}>
						<div>
							<h1>London</h1>
						</div>
						<div>
							<h1>Berlin</h1>
						</div>
						<div>
							<h1>Dubai</h1>
						</div>
						<div>
							<h1>Dublin</h1>
						</div>
						<div>
							<h1>Warsaw</h1>
						</div>
						<div>
							<h1>Luxembourg</h1>
						</div>
						<div>
							<h1>Malta</h1>
						</div>
						<div>
							<h1>Cyprus</h1>
						</div>
						<div>
							<h1>London</h1>
						</div>
						<div>
							<h1>Berlin</h1>
						</div>
						<div>
							<h1>Dubai</h1>
						</div>
						<div>
							<h1>Dublin</h1>
						</div>
						<div>
							<h1>Warsaw</h1>
						</div>
						<div>
							<h1>Luxembourg</h1>
						</div>
						<div>
							<h1>Malta</h1>
						</div>
						<div>
							<h1>Cyprus</h1>
						</div>

						<div>
							<h1>London</h1>
						</div>
						<div>
							<h1>Berlin</h1>
						</div>
						<div>
							<h1>Dubai</h1>
						</div>
						<div>
							<h1>Dublin</h1>
						</div>
						<div>
							<h1>Warsaw</h1>
						</div>
						<div>
							<h1>Luxembourg</h1>
						</div>
						<div>
							<h1>Malta</h1>
						</div>
						<div>
							<h1>Cyprus</h1>
						</div>

						<div>
							<h1>London</h1>
						</div>
						<div>
							<h1>Berlin</h1>
						</div>
						<div>
							<h1>Dubai</h1>
						</div>
						<div>
							<h1>Dublin</h1>
						</div>
						<div>
							<h1>Warsaw</h1>
						</div>
						<div>
							<h1>Luxembourg</h1>
						</div>
						<div>
							<h1>Malta</h1>
						</div>
						<div>
							<h1>Cyprus</h1>
						</div>

						<div>
							<h1>London</h1>
						</div>
						<div>
							<h1>Berlin</h1>
						</div>
						<div>
							<h1>Dubai</h1>
						</div>
						<div>
							<h1>Dublin</h1>
						</div>
						<div>
							<h1>Warsaw</h1>
						</div>
						<div>
							<h1>Luxembourg</h1>
						</div>
						<div>
							<h1>Malta</h1>
						</div>
						<div>
							<h1>Cyprus</h1>
						</div>

						<div>
							<h1>London</h1>
						</div>
						<div>
							<h1>Berlin</h1>
						</div>
						<div>
							<h1>Dubai</h1>
						</div>
						<div>
							<h1>Dublin</h1>
						</div>
						<div>
							<h1>Warsaw</h1>
						</div>
						<div>
							<h1>Luxembourg</h1>
						</div>
						<div>
							<h1>Malta</h1>
						</div>
						<div>
							<h1>Cyprus</h1>
						</div>

						<div>
							<h1>London</h1>
						</div>
						<div>
							<h1>Berlin</h1>
						</div>
						<div>
							<h1>Dubai</h1>
						</div>
						<div>
							<h1>Dublin</h1>
						</div>
						<div>
							<h1>Warsaw</h1>
						</div>
						<div>
							<h1>Luxembourg</h1>
						</div>
						<div>
							<h1>Malta</h1>
						</div>
						<div>
							<h1>Cyprus</h1>
						</div>

						<div>
							<h1>London</h1>
						</div>
						<div>
							<h1>Berlin</h1>
						</div>
						<div>
							<h1>Dubai</h1>
						</div>
						<div>
							<h1>Dublin</h1>
						</div>
						<div>
							<h1>Warsaw</h1>
						</div>
						<div>
							<h1>Luxembourg</h1>
						</div>
						<div>
							<h1>Malta</h1>
						</div>
						<div>
							<h1>Cyprus</h1>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}
