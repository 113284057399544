import React, { useEffect, useState, useRef } from 'react';
import styles from './FamilyOfFunds.module.scss';

import classNames from 'classnames';
import useOnScreen from '../../../hooks/useOnScreen';
import { FAMILY_OF_FOUNDS } from './data';
export default function FamilyOfFunds() {
	const ref = useRef(null);

	const [reveal, setReveal] = useState(false);
	const onScreen = useOnScreen(ref);

	useEffect(() => {
		if (onScreen) setReveal(onScreen);
	}, [onScreen]);
	return (
		<div data-scroll-section>
			<section className={styles.familyforFundsSectionAlignment}>
				<div className='container'>
					<div className={styles.pageTitle}>
						<div>
							<h1
								className='c-header_title o-h1'
								data-scroll
								data-scroll-speed='0.5'
								data-scroll-repeat
							>
								<span
									className={classNames('c-header_title_line', {
										'is-reveal': reveal,
									})}
								>
									<span
										data-scroll
										data-scroll-speed='1'
										data-scroll-repeat
										data-scroll-position='top'
									>
										Management of{' '}
									</span>
									<span
										data-scroll
										data-scroll-speed='1'
										data-scroll-position='top'
										data-scroll-repeat
										className='italic-class'
									>
										Diversified Funds
									</span>
								</span>
							</h1>
						</div>

						<div
							data-scroll
							data-scroll-speed='1'
						>
							<a>Portfolio</a>
						</div>
						<div
							data-scroll
							data-scroll-speed='1'
						>
							<p>
								LRC has established a series of closed-ended funds in
								collaboration with a dedicated{' '}
								<a
									href='https://www.mfsa.mt/financial-services-register/'
									target='_blank'
									rel='noopener noreferrer'
								>
									AIFM
								</a>
								, exclusively managing LRC’s investment vehicles to ensure
								efficient fund administration and strategic oversight. .
							</p>
						</div>
					</div>
					<div className={styles.grid}>
						{FAMILY_OF_FOUNDS.map(item => (
							<div className={styles.gridItems}>
								<div className={styles.cardImages}>
									<img
										src={item.img}
										alt='CardImage'
										className='flipImage'
										data-scroll
										data-scroll-repeat
									/>
								</div>
								<div className={styles.cardDetails}>
									<h2
										className='c-header_title o-h1'
										data-scroll
										data-scroll-speed='0.5'
										data-scroll-repeat
									>
										<span
											className={classNames('c-header_title_line', {
												'is-reveal': reveal,
											})}
										>
											<span
												data-scroll
												data-scroll-speed='1'
												data-scroll-repeat
												data-scroll-position='top'
											>
												{item.title}
											</span>
										</span>
									</h2>
									{/* <h2>RE-1 <span>€700m</span></h2> */}
									<div
										data-scroll
										data-scroll-speed='1'
									>
										<p>
											{item.description}
										</p>
										<a>{item.location}</a>
									</div>
								</div>
							</div>
						))}

						
					</div>
				</div>
			</section>
		</div>
	);
}
