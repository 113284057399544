import ImageOne from '../../../Assets/home-page-images/slider-images/1.webp';
import ImageTwo from '../../../Assets/home-page-images/slider-images/2.webp';
import ImageThree from '../../../Assets/home-page-images/slider-images/3.webp';
import ImageFour from '../../../Assets/home-page-images/slider-images/4.webp';
import ImageFive from '../../../Assets/home-page-images/slider-images/5.webp';
import ImageSix from '../../../Assets/home-page-images/slider-images/6.webp';
import ImageSeven from '../../../Assets/home-page-images/slider-images/7.webp';
import ImageEight from '../../../Assets/home-page-images/slider-images/8.webp';
import ImageNine from '../../../Assets/home-page-images/slider-images/9.webp';
import ImageTen from '../../../Assets/home-page-images/slider-images/10.webp';
import ImageEleven from '../../../Assets/home-page-images/slider-images/11.webp';
import ImageTwelve from '../../../Assets/home-page-images/slider-images/12.webp';
import ImageThirteen from '../../../Assets/home-page-images/slider-images/13.webp';
import ImageFourteen from '../../../Assets/home-page-images/slider-images/14.webp';

export const HOSPITALITY_EXPERIENCE_IMAGES = [
	ImageOne,
	ImageTwo,
	ImageThree,
	ImageFour,
	ImageFive,
	ImageSix,
	ImageSeven,
	ImageEight,
	ImageNine,
	ImageTen,
	ImageEleven,
	ImageTwelve,
	ImageThirteen,
	ImageFourteen,
];

export const HOSPITALITY_EXPERIENCE_CARDS = [
	{
		title: 'Residential',
		description:
			"LRC's robust found infrastructure enables cost efficient residential acquisitions with scalability, while its proven aggregation strategy drives additional value.",
		link: '/expertise',
	},
	{
		title: 'Commercial & Hospitality',
		description:
			'LRC has a strong track record in acquiring and managing commercial real estate, delivering stable cash flows and value creation for institutional investors. Its expertise also extends to hospitality investments, having successfully operated and exited a pan-European hotels portfolio.',
		link: '/expertise',
	},
	{
		title: 'Alternative Living',
		description:
			"LRC Group's tech-enabled co-living platform, Vonder, offers fully furnished, all-in-one living for digital nomads and young professionals, combining convenience, amenities, and vibrant communities",
		link: '/expertise',
	},
];
