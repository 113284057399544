import React from 'react';
import classNames from 'classnames';
import styles from './Timeline.module.scss';
export default function Timeline() {
	return (
		<div data-scroll-section>
			<div className='container'>
				<div className={styles.timelineCenterAlignment}>
					<div className={styles.timeline}>
						<div className={classNames(styles.timelineContainer, styles.left)}>
							<div
								className={styles.content}
								data-scroll
								data-scroll-speed='1'
							>
								<h2>1995</h2>
								<p>Founded in London with a focus on residential real estate</p>
							</div>
						</div>
						<div className={classNames(styles.timelineContainer, styles.right)}>
							<div
								className={styles.content}
								data-scroll
								data-scroll-speed='1'
							>
								<h2>2005</h2>
								<p>
									Expansion into Germany with residential and commercial real
									estate acquired from 2005 to 2015
								</p>
							</div>
						</div>
						<div className={classNames(styles.timelineContainer, styles.left)}>
							<div
								className={styles.content}
								data-scroll
								data-scroll-speed='1'
							>
								<h2>2015</h2>

								<p>
									Acquisition of sizeable commercial portfolio in the
									Netherlands
								</p>
							</div>
						</div>
						<div className={classNames(styles.timelineContainer, styles.right)}>
							<div
								className={styles.content}
								data-scroll
								data-scroll-speed='1'
							>
								<h2>2016</h2>
								<ul>
									<li>Acquisition of commercial assets in Poland</li>
									<li>Acquisition of residential assets in Ireland</li>
								</ul>
							</div>
						</div>
						<div className={classNames(styles.timelineContainer, styles.left)}>
							<div
								className={styles.content}
								data-scroll
								data-scroll-speed='1'
							>
								<h2>2017</h2>
								<p>Acquisition of residential assets in the UK & Ireland</p>
							</div>
						</div>
						<div className={classNames(styles.timelineContainer, styles.right)}>
							<div
								className={styles.content}
								data-scroll
								data-scroll-speed='1'
							>
								<h2>2018</h2>
								<ul>
									<li>Acquisition of residential assets in Poland </li>
									<li>Launch of Vonder in Germany </li>
									<li>
										Acquisition of Hospitality portfolio in the UK and launch of
										Amaris Hospitality
									</li>
									<li>
										Launch of Ireland residential fund backed by large
										Institutional investors
									</li>
									<li>
										<b>Peak AUM of €6.3bn</b>
									</li>
								</ul>
							</div>
						</div>
						<div className={classNames(styles.timelineContainer, styles.left)}>
							<div
								className={styles.content}
								data-scroll
								data-scroll-speed='1'
							>
								<h2>2019</h2>
								<ul>
									<li>Exit from the Netherlands through portfolio sale</li>
									<li>
										Acquisition of an office campus in France with Institutional
										investors
									</li>
									<li>Launch of Vonder UK</li>
								</ul>
							</div>
						</div>
						<div className={classNames(styles.timelineContainer, styles.right)}>
							<div
								className={styles.content}
								data-scroll
								data-scroll-speed='1'
							>
								<h2>2021</h2>
								<ul>
									<li>
										Launch of UK residential fund I, backed by large
										Institutional investors
									</li>
									<li>Exit from UK Hospitality portfolio</li>
									<li>Exit from most positions in Germany</li>
									<li>Launch of Vonder in Poland & Dubai</li>
								</ul>
							</div>
						</div>
						<div
							className={classNames(
								styles.timelineContainer,
								styles.left,
								styles.contentspaceRemove
							)}
						>
							<div
								className={classNames(
									styles.content,
									styles.contentspaceRemove1
								)}
								data-scroll
								data-scroll-speed='1'
							>
								<h2>2023</h2>
								<ul>
									<p>
										Launch of UK residential fund II, backed by repeat
										Institutional investors
									</p>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
