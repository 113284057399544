import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import useOnScreen from '../../../hooks/useOnScreen';
import styles from './OurTeam.module.scss';
export default function OurTeam() {
	const ref = useRef(null);

	const [reveal, setReveal] = useState(false);
	const onScreen = useOnScreen(ref);

	useEffect(() => {
		if (onScreen) setReveal(onScreen);
	}, [onScreen]);
	return (
		<div data-scroll-section>
			<section className={styles.ourTeamBanner}>
				<div className='container'>
					<div className={styles.grid}>
						<div className={styles.gridItems}>
							<a
								data-scroll
								data-scroll-speed='0.5'
							>
								LRC’s Management team
							</a>

							<h1
								className='c-header_title o-h1'
								data-scroll
								data-scroll-speed='0.5'
								data-scroll-repeat
							>
								<span
									className={classNames('c-header_title_line', {
										'is-reveal': reveal,
									})}
								>
									Experienced &{' '}
									<span className='italic-class'>
										dynamic in-house management team
									</span>
								</span>
							</h1>
						</div>
						<div className={styles.gridItems}>
							<div
								data-scroll
								data-scroll-speed='1'
							>
								<p>
									LRC Group is led by a highly experienced, results-driven
									management team with deep expertise in real estate investment,
									asset management, and finance. With over 125 years of combined
									experience, LRC’s principals have been actively engaged in
									European and international property markets for decades,
									bringing a wealth of industry knowledge and strategic insight.
								</p>
								<p>
									The Group’s dynamic leadership enables it to proactively
									navigate market trends, adapt to evolving real estate
									landscapes, and drive innovation across its portfolio. By
									integrating cutting-edge technologies and sustainable business
									practices, LRC continuously enhances operational efficiency
									and long-term value creation.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}
