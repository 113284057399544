import React, { useEffect, useState, useRef } from 'react';
import styles from './HospitalityExperience.module.scss';
import classNames from 'classnames';
import useOnScreen from '../../../hooks/useOnScreen';
import Slider from 'react-slick';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import SliderLeftRightArrow from '../../../Assets/Images/slider-next.svg';
import SliderLeftLeftArrow from '../../../Assets/Images/slider-right.svg';
import {
	HOSPITALITY_EXPERIENCE_CARDS,
	HOSPITALITY_EXPERIENCE_IMAGES,
} from './hospitaly-data';

function SampleNextArrow(props) {
	const { onClick } = props;
	return (
		<div
			className={classNames(styles.sliderArrow, styles.sliderLeftArrow)}
			onClick={onClick}
		>
			<img
				src={SliderLeftLeftArrow}
				alt='LeftArrow'
			/>
		</div>
	);
}

function SamplePrevArrow(props) {
	const { onClick } = props;
	return (
		<div
			className={classNames(styles.sliderArrow, styles.sliderRightArrow)}
			onClick={onClick}
		>
			<img
				src={SliderLeftRightArrow}
				alt='RightArrow'
			/>
		</div>
	);
}

export default function HospitalityExperience() {
	var settings = {
		dots: false,
		infinite: true,
		speed: 3000,
		arrows: true,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
		autoplay: true,
		slidesToShow: 2.01,
		slidesToScroll: 1.01,

		responsive: [
			{
				breakpoint: 768,

				settings: {
					speed: 3000,
					slidesToShow: 1.01,
					slidesToScroll: 1.01,
					initialSlide: 1.1,
				},
			},
			{
				breakpoint: 100,
				settings: {
					speed: 3000,
					slidesToShow: 1.2,
					slidesToScroll: 1.2,
				},
			},
		],
	};

	const ref = useRef(null);

	const [reveal, setReveal] = useState(false);
	const onScreen = useOnScreen(ref);

	useEffect(() => {
		if (onScreen) setReveal(onScreen);
	}, [onScreen]);
	return (
		<div data-scroll-section>
			<section className={styles.hospitalityExperienceBanner}>
				<div className='container'>
					<div className={styles.newPageTitle}>
						<div
							data-scroll
							data-scroll-speed='1'
						>
							<a>VERTICALS</a>
						</div>
						<h1
							className='c-header_title o-h1'
							data-scroll
							data-scroll-speed='0.5'
							data-scroll-repeat
						>
							<span
								className={classNames('c-header_title_line', {
									'is-reveal': reveal,
								})}
							>
								<span
									data-scroll
									data-scroll-speed='1'
									data-scroll-repeat
									data-scroll-position='top'
								>
									LRC’s{' '}
								</span>
								<span
									className='italic-class'
									data-scroll
									data-scroll-speed='1'
									data-scroll-position='top'
									data-scroll-repeat
								>
									Multidisciplinary Expertise{' '}
								</span>
							</span>
						</h1>
					</div>
					<div className={styles.expertiseTextGrid}>
						{HOSPITALITY_EXPERIENCE_CARDS.map(item => (
							<div
								key={item.description + 'home'}
								className={styles.expertiseTextGridItems}
							>
								<h2
									className='c-header_title o-h1'
									data-scroll
									data-scroll-speed='0.5'
									data-scroll-repeat
								>
									<span
										className={classNames('c-header_title_line', {
											'is-reveal': reveal,
										})}
									>
										<span
											data-scroll
											data-scroll-speed='1'
											data-scroll-repeat
											data-scroll-position='top'
										>
											{item.title}
										</span>
									</span>
								</h2>
								<div
									data-scroll
									data-scroll-speed='1'
								>
									<p>{item.description}</p>
									<NavLink to={item.link}>
										<button>
											<span>Learn more</span>
											<svg
												width='14'
												height='12'
												viewBox='0 0 14 12'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
											>
												<path
													d='M7.24473 0.412046C7.57016 0.0866095 8.0978 0.0866095 8.42324 0.412046L13.4232 5.41205C13.5795 5.56833 13.6673 5.78029 13.6673 6.0013C13.6673 6.22232 13.5795 6.43428 13.4232 6.59056L8.42324 11.5906C8.0978 11.916 7.57016 11.916 7.24473 11.5906C6.91929 11.2651 6.91929 10.7375 7.24473 10.412L10.8221 6.83464L1.16732 6.83464C0.70708 6.83464 0.333984 6.46154 0.333984 6.0013C0.333984 5.54107 0.70708 5.16797 1.16732 5.16797L10.8221 5.16797L7.24473 1.59056C6.91929 1.26512 6.91929 0.737483 7.24473 0.412046Z'
													fill='#333B49'
												/>
											</svg>
										</button>
									</NavLink>
								</div>
							</div>
						))}
					</div>

					<div className={styles.galleryTextGrid}>
						<div className={styles.galleryTextGridItems}>
							<h1
								className='c-header_title o-h1'
								data-scroll
								data-scroll-speed='0.5'
								data-scroll-repeat
							>
								<span
									className={classNames('c-header_title_line', {
										'is-reveal': reveal,
									})}
								>
									<span
										data-scroll
										data-scroll-speed='1'
										data-scroll-repeat
										// data-scroll-position="top"
									>
										A diverse and attractive portfolio under management with a
										strong focus on the UK & Ireland:
									</span>
								</span>
							</h1>
						</div>
					</div>
				</div>
				<div className={styles.asas}>
					<Slider
						{...settings}
						autoplay
						centerMode={true}
					>
						{HOSPITALITY_EXPERIENCE_IMAGES.map((rep, i) => {
							return (
								<div
									className={styles.rightImageAlignment}
									key={i}
								>
									<img
										src={rep}
										alt='GalleryImage'
									/>
								</div>
							);
						})}
					</Slider>
				</div>
			</section>
		</div>
	);
}
