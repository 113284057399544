export const THE_GROUP_IN_NUMBERS = [
	{
		number: '1995',
		text: 'Founded',
	},
	{
		number: '8',
		text: 'International Offices',
	},
	{
		number: '147',
		text: 'Professionals',
	},
	{
		number: '€10.1bn',
		text: 'Total Deal Volume Since Inception',
	},
	{
		number: '20,000+',
		text: 'Apartments Previously Managed',
	},
	{
		number: '3.4m SQM',
		text: 'Commercial Space Previously Managed',
	},
];
