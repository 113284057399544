import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import useOnScreen from '../../../hooks/useOnScreen';
import styles from './OurHistory.module.scss';
import OurHistoryImage from '../../../Assets/Images/our-history.png';
export default function OurHistory() {
	const ref = useRef(null);

	const [reveal, setReveal] = useState(false);
	const onScreen = useOnScreen(ref);

	useEffect(() => {
		if (onScreen) setReveal(onScreen);
	}, [onScreen]);
	return (
		<div data-scroll-section>
			<section className={styles.OurHistoryAlignment}>
				<div className='container'>
					<div className={styles.grid}>
						<div className={styles.gridItems}>
							<img
								src={OurHistoryImage}
								alt='OurHistoryImage'
								className='flipImage'
								data-scroll
								data-scroll-repeat
							/>
						</div>
						<div className={styles.gridItems}>
							<div>
								<a>LRC’s history</a>
							</div>
							<h1
								className='c-header_title o-h1'
								data-scroll
								data-scroll-speed='0.5'
								data-scroll-repeat
							>
								<span
									className={classNames('c-header_title_line', {
										'is-reveal': reveal,
									})}
								>
									Over 25 years of{' '}
									<span className='italic-class'>expertise</span>
								</span>
							</h1>
							<div
								data-scroll
								data-scroll-speed='1'
							>
								<p>
									Founded in 1995, LRC Group has expanded its footprint across
									key international markets, leveraging its vertically
									integrated platform and multi-disciplinary. With a strategic
									presence across Europe and beyond, LRC actively identifies
									high-potential investment opportunities, optimizes asset
									performance, and drives long-term value creation for
									investors.
								</p>
								<p>
									With €3.6 billion in assets under management, LRC oversees a
									diverse portfolio of over 9,000 residential units and 500,000
									sqm of commercial space. As LRC continues to grow, it remains
									focused on expanding its portfolio while maintaining
									operational excellence.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}
