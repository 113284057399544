import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import useOnScreen from '../../../hooks/useOnScreen';
import styles from './GeneratingInterest.module.scss';
import ArchitecutureImage from '../../../Assets/Images/architecuture.png';
import ShowImage from '../../../Assets/Images/show-imgae.png';
export default function GeneratingInterest() {
	const ref = useRef(null);

	const [reveal, setReveal] = useState(false);
	const onScreen = useOnScreen(ref);

	useEffect(() => {
		if (onScreen) setReveal(onScreen);
	}, [onScreen]);
	return (
		<div data-scroll-section>
			<section className={styles.GeneratingInterestAlignment}>
				<div className={styles.grid}>
					<div className={styles.gridItems}>
						<div className={styles.sectionTitle}>
							<div className={styles.mobileViewTextShow}>
								<h5>DESIGN & value engIneering</h5>
							</div>
							<h1>
								Our Commitment to <b>ESG</b>
							</h1>

							<p>
								At LRC Group, we recognize that our success as a pan-European
								real estate investment and fund manager is intrinsically linked
								to the well-being of the environment, the communities we operate
								in, and the governance practices we uphold.
							</p>
						</div>
						<div className={styles.fullViewImage}>
							<img
								src={ShowImage}
								alt='ShowImage'
								className='flipImage'
								data-scroll
								data-scroll-repeat
							/>
						</div>
					</div>
					<div className={styles.gridItems}>
						<a>DESIGN & value engIneering</a>
						<div className={styles.imageStyle}>
							<img
								src={ArchitecutureImage}
								alt='ArchitecutureImage'
								className='flipImage'
								data-scroll
								data-scroll-repeat
							/>
							{/* <h6 className="italic-class">Building</h6> */}
						</div>
						<div className={styles.imageRelatedText}>
							<span>
								We believe that integrating Environmental, Social, and
								Governance (ESG) principles into every aspect of our business is
								not just a responsibility but a driver of long-term value
								creation for our stakeholders.
							</span>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}
