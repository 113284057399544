import ImageOne from '../../../Assets/home-page-images/lrc-groups/1.webp';
import ImageTwo from '../../../Assets/home-page-images/lrc-groups/2.webp';
import ImageThree from '../../../Assets/home-page-images/lrc-groups/3.webp';
import ImageFour from '../../../Assets/home-page-images/lrc-groups/4.webp';
export const FAMILY_OF_FOUNDS = [
	{
		id: 1,
		title: 'Irish Residential Fund',
		description:
			'2,200 apartments, bringing under-utilised accommodation back onto the market.',
		location: 'Ireland',
		img: ImageOne,
	},
	{
		id: 2,
		title: 'UK Residential Fund I & II',
		description:
			'4,500 apartments, bringing under-utilised accommodation back onto the market.',
		location: 'United kingdom',
		img: ImageTwo,
	},
];
