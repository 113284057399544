import React, { useEffect, useState, useRef } from 'react';
import styles from './Pioneering.module.scss';
import PioneeringImage from '../../../Assets/home-page-images/what-we-do-bg.webp';
import { NavLink } from 'react-router-dom';
import WhatWeDo from '../../../Assets/Images/aerial-city.png';
import classNames from 'classnames';
import useOnScreen from '../../../hooks/useOnScreen';
import { WHAT_WE_DO_CARDS } from './pionering-data';
export default function Pioneering() {
	const ref = useRef(null);

	const [reveal, setReveal] = useState(false);
	const onScreen = useOnScreen(ref);

	useEffect(() => {
		if (onScreen) setReveal(onScreen);
	}, [onScreen]);
	return (
		<div data-scroll-section>
			<section className={styles.pioneeringSectionAlignment}>
				<div className={classNames(styles.containerSpaceRemove, 'container')}>
					<div className={styles.textGrid}>
						<div className={styles.textGridItems}>
							<div
								data-scroll
								data-scroll-speed='1'
							>
								<a>what we do</a>
							</div>
							<h1
								className='c-header_title o-h1'
								data-scroll
								data-scroll-speed='0.5'
								data-scroll-repeat
							>
								<span
									className={classNames('c-header_title_line', {
										'is-reveal': reveal,
									})}
								>
									Strong <b>Pan-European Footprint</b>
								</span>
							</h1>
							<p
								data-scroll
								data-scroll-speed='1'
							>
								LRC strategically manages funds and assets across European
								markets characterized by strong demographic and economic
								fundamentals. In the UK and Ireland, resilient economies and
								sustained population growth create compelling residential
								investment opportunities. Across continental Europe, select
								markets benefit from favorable demographic trends and dynamic
								economic activity. LRC also pursues opportunistic investments in
								targeted sectors, leveraging market-specific advantages.
							</p>
							<NavLink to='/urbanregeneration'>
								<button>
									<span>Learn More</span>
									<svg
										width='14'
										height='12'
										viewBox='0 0 14 12'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M7.24473 0.412046C7.57016 0.0866095 8.0978 0.0866095 8.42324 0.412046L13.4232 5.41205C13.5795 5.56833 13.6673 5.78029 13.6673 6.0013C13.6673 6.22232 13.5795 6.43428 13.4232 6.59056L8.42324 11.5906C8.0978 11.916 7.57016 11.916 7.24473 11.5906C6.91929 11.2651 6.91929 10.7375 7.24473 10.412L10.8221 6.83464L1.16732 6.83464C0.70708 6.83464 0.333984 6.46154 0.333984 6.0013C0.333984 5.54107 0.70708 5.16797 1.16732 5.16797L10.8221 5.16797L7.24473 1.59056C6.91929 1.26512 6.91929 0.737483 7.24473 0.412046Z'
											fill='white'
										/>
									</svg>
								</button>
							</NavLink>
						</div>
						<div className={styles.textGridItems}>
							<img
								src={PioneeringImage}
								alt='PioneeringImage'
								className='flipImage'
								data-scroll
								data-scroll-repeat
							/>
						</div>
					</div>
					
				</div>
				<div className={styles.withoutContainerAlignment}>
					<div className={styles.techImageGrid}>
						<div className={styles.techImageGridItems}>
							<img
								src={WhatWeDo}
								alt='WhatWeDo'
								className='flipImage'
								data-scroll
								data-scroll-repeat
							/>
						</div>
						<div className={styles.techImageGridItems}>
							<div className={styles.newTextGrid}>
								{WHAT_WE_DO_CARDS.map(item => (
									<div
										key={item.title}
										className={styles.newTextGridItems}
									>
										<h4
											className='c-header_title o-h1'
											data-scroll
											data-scroll-speed='1'
										>
											<span
												className={classNames('c-header_title_line', {
													'is-reveal': reveal,
												})}
											>
												{item.title}
											</span>
										</h4>
										<p
											data-scroll
											data-scroll-speed='1'
										>
											{item.description}
										</p>
									</div>
								))}
								
							</div>
							<div className='container'>
								<div className={styles.mobileViewPioneeringButtonShow}>
									<NavLink to='/urbanregeneration'>
										<button>
											<span>Learn More</span>
											<svg
												width='14'
												height='12'
												viewBox='0 0 14 12'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
											>
												<path
													d='M7.24473 0.412046C7.57016 0.0866095 8.0978 0.0866095 8.42324 0.412046L13.4232 5.41205C13.5795 5.56833 13.6673 5.78029 13.6673 6.0013C13.6673 6.22232 13.5795 6.43428 13.4232 6.59056L8.42324 11.5906C8.0978 11.916 7.57016 11.916 7.24473 11.5906C6.91929 11.2651 6.91929 10.7375 7.24473 10.412L10.8221 6.83464L1.16732 6.83464C0.70708 6.83464 0.333984 6.46154 0.333984 6.0013C0.333984 5.54107 0.70708 5.16797 1.16732 5.16797L10.8221 5.16797L7.24473 1.59056C6.91929 1.26512 6.91929 0.737483 7.24473 0.412046Z'
													fill='white'
												/>
											</svg>
										</button>
									</NavLink>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}
