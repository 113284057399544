import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import useOnScreen from '../../../hooks/useOnScreen';
import styles from './ResidentialSection.module.scss';
import PropertyImage from '../../../Assets/expertise-page/residental/1.jpg';
import Hospitality from '../../../Assets/expertise-page/hospitality.png';
import CommericalImage1 from '../../../Assets/expertise-page/commercial.jpg';
import LivingImage from '../../../Assets/expertise-page/residental/2.jpg';
import Vonder from '../../../Assets/expertise-page/vonder.jpg';

export default function ResidentialSection() {
	const ref = useRef(null);

	const [reveal, setReveal] = useState(false);
	const onScreen = useOnScreen(ref);

	useEffect(() => {
		if (onScreen) setReveal(onScreen);
	}, [onScreen]);
	return (
		<div data-scroll-section>
			<div className={styles.ResidentialSectionBottomAlignment}>
				<section className='container'>
					<div className={styles.ResidentialSectionAlignment}>
						<div className={styles.titleAlign}>
							<h1
								className='c-header_title o-h1'
								data-scroll
								data-scroll-speed='1'
								data-scroll-repeat
							>
								<span
									className={classNames('c-header_title_line', {
										'is-reveal': reveal,
									})}
								>
									<span
										data-scroll
										data-scroll-speed='1'
										data-scroll-repeat
										data-scroll-position='top'
									>
										Residential
									</span>
								</span>
							</h1>
						</div>
						<div className={styles.boxEndSide}>
							<div>
								<div
									className={classNames(styles.grid, styles.gridBottomAlign)}
								>
									<div className={styles.gridItems}>
										<h1
											className='c-header_title o-h1'
											data-scroll
											data-scroll-speed='1'
											data-scroll-repeat
										>
											<span
												className={classNames('c-header_title_line', {
													'is-reveal': reveal,
												})}
											>
												<span
													data-scroll
													data-scroll-speed='1'
													data-scroll-repeat
													data-scroll-position='top'
												>
													Fund Infrastructure
												</span>
											</span>
										</h1>
										<p
											data-scroll
											data-scroll-speed='1'
										>
											The LRC Group's well-established fund infrastructure
											enables it to efficiently acquire residential assets at a
											low marginal cost, while demonstrating a high level of
											efficiency and scalability. LRC's platform's proven
											experience in aggregation strategy further contributes to
											additional upside.
										</p>
									</div>
									<div className={styles.gridItems}>
										<img
											className={styles.mobileViewHiddenImage}
											src={PropertyImage}
											alt='PropertyImage'
										/>
										<img
											className={styles.mobileViewImaageShow}
											src={PropertyImage}
											alt='PropertyImageMobile'
										/>
									</div>
								</div>
								<div className={classNames(styles.grid)}>
									<div className={styles.gridItems}>
										<h1
											className='c-header_title o-h1'
											data-scroll
											data-scroll-speed='1'
											data-scroll-repeat
										>
											<span
												className={classNames('c-header_title_line', {
													'is-reveal': reveal,
												})}
											>
												<span
													data-scroll
													data-scroll-speed='1'
													data-scroll-repeat
													data-scroll-position='top'
												>
													One of the Largest Professional Landlords in the UK
													and Ireland
												</span>
											</span>
										</h1>
										<p
											data-scroll
											data-scroll-speed='1'
										>
											LRC is a leading institutional landlord with a significant
											residential footprint across the UK, Ireland, and
											continental Europe. Its Irish Residential Fund manages
											over 2,000 apartments, while its two UK Residential Funds
											hold nearly 5,000 units combined. Beyond the UK and
											Ireland, LRC also oversees substantial residential
											portfolios in Germany and Poland, reinforcing its presence
											in key European markets. Through active asset management
											and strategic investments, LRC continues to enhance its
											residential holdings, delivering high-quality living
											spaces and strong, long-term returns.
										</p>
									</div>
									<div className={styles.gridItems}>
										<img
											className={styles.mobileViewHiddenImage}
											src={LivingImage}
											alt='LivingImage'
										/>
										<img
											className={styles.mobileViewImaageShow}
											src={LivingImage}
											alt='LivingImageMobile'
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className={styles.ResidentialSectionAlignment}>
						<div className={styles.titleAlign}>
							<h1
								className='c-header_title o-h1'
								data-scroll
								data-scroll-speed='1'
								data-scroll-repeat
							>
								<span
									className={classNames('c-header_title_line', {
										'is-reveal': reveal,
									})}
								>
									<span
										data-scroll
										data-scroll-speed='1'
										data-scroll-repeat
										data-scroll-position='top'
									>
										Commercial
									</span>
								</span>
							</h1>
						</div>
						<div className={styles.boxEndSide}>
							<div>
								<div className={classNames(styles.grid)}>
									<div className={styles.gridItems}>
										<h1
											className='c-header_title o-h1'
											data-scroll
											data-scroll-speed='1'
											data-scroll-repeat
										>
											<span
												className={classNames('c-header_title_line', {
													'is-reveal': reveal,
												})}
											>
												<span
													data-scroll
													data-scroll-speed='1'
													data-scroll-repeat
													data-scroll-position='top'
												>
													Expertise Across Commercial Asset Classes
												</span>
											</span>
										</h1>
										<p
											data-scroll
											data-scroll-speed='1'
										>
											LRC has a proven track record in owning, managing, and
											optimizing a diverse portfolio of commercial assets,
											including office, retail, logistics, and light industrial
											properties. Over the past decade, LRC has managed more
											than three million sqm of commercial space across Europe,
											leasing to long-term corporate partners across various
											industries.
										</p>
										<p
											data-scroll
											data-scroll-speed='1'
											style={{ marginTop: '20px' }}
										>
											By combining proactive asset management with a focus on
											value optimization, LRC enhances both property performance
											and tenant experience. Through rigorous operational
											oversight and strategic enhancements, LRC ensures its
											commercial assets meet the highest standards, driving
											long-term value creation and strong investment returns.
										</p>
									</div>
									<div className={styles.gridItems}>
										<img
											className={styles.mobileViewHiddenImage}
											src={CommericalImage1}
											alt='CommericalImage'
										/>
										<img
											className={styles.mobileViewImaageShow}
											src={CommericalImage1}
											alt='CommericalImage1Mobile'
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className={styles.ResidentialSectionAlignment}>
						<div className={styles.titleAlign}>
							<h1
								className='c-header_title o-h1'
								data-scroll
								data-scroll-speed='1'
								data-scroll-repeat
							>
								<span
									className={classNames('c-header_title_line', {
										'is-reveal': reveal,
									})}
								>
									<span
										data-scroll
										data-scroll-speed='1'
										data-scroll-repeat
										data-scroll-position='top'
									>
										Hospitality
									</span>
								</span>
							</h1>
						</div>
						<div className={styles.boxEndSide}>
							<div>
								<div className={classNames(styles.grid)}>
									<div className={styles.gridItems}>
										<h1
											className='c-header_title o-h1'
											data-scroll
											data-scroll-speed='1'
											data-scroll-repeat
										>
											<span
												className={classNames('c-header_title_line', {
													'is-reveal': reveal,
												})}
											>
												<span
													data-scroll
													data-scroll-speed='1'
													data-scroll-repeat
													data-scroll-position='top'
												>
													Leading Hospitality Management
												</span>
											</span>
										</h1>
										<p
											data-scroll
											data-scroll-speed='1'
										>
											LRC’s hospitality platform, Amaris, has a strong track
											record in managing and optimizing hotel assets, overseeing
											30 hotels and 4,900 rooms across Europe under prestigious
											international brands such as Hilton, Mercure, and Ibis
											Styles.
										</p>
										<p
											data-scroll
											data-scroll-speed='1'
											style={{ marginTop: '20px' }}
										>
											With deep industry expertise, Amaris has consistently
											elevated the quality, operational efficiency, and
											financial performance of its portfolio. Through strategic
											asset management, targeted refurbishments, and
											best-in-class hospitality operations, Amaris has
											successfully enhanced guest experiences, maximized
											occupancy rates, and driven sustainable revenue growth.
										</p>
										<p
											data-scroll
											data-scroll-speed='1'
											style={{ marginTop: '20px' }}
										>
											Backed by LRC’s commitment to long-term value creation,
											Amaris continues to identify and capitalize on
											opportunities within the dynamic hospitality sector.
										</p>
									</div>
									<div className={styles.gridItems}>
										<img
											src={Hospitality}
											alt='CommericalImage'
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className={styles.ResidentialSectionAlignment}>
						<div className={styles.titleAlign}>
							<h1
								className='c-header_title o-h1'
								data-scroll
								data-scroll-speed='1'
								data-scroll-repeat
							>
								<span
									className={classNames('c-header_title_line', {
										'is-reveal': reveal,
									})}
								>
									<span
										data-scroll
										data-scroll-speed='1'
										data-scroll-repeat
										data-scroll-position='top'
									>
										Alternative Living
									</span>
								</span>
							</h1>
						</div>
						<div className={styles.boxEndSide}>
							<div>
								<div className={classNames(styles.grid)}>
									<div className={styles.gridItems}>
										<h1
											className='c-header_title o-h1'
											data-scroll
											data-scroll-speed='1'
											data-scroll-repeat
										>
											<span
												className={classNames('c-header_title_line', {
													'is-reveal': reveal,
												})}
											>
												<span
													data-scroll
													data-scroll-speed='1'
													data-scroll-repeat
													data-scroll-position='top'
												>
													A Tech-Enabled Lifestyle-Driven Platform
												</span>
											</span>
										</h1>

										<p
											data-scroll
											data-scroll-speed='1'
											style={{ marginTop: '20px' }}
										>
											<a
												href='http://www.vondereurope.com/'
												target='_blank'
												style={{textDecoration: 'underline'}}
												rel='noreferrer'
											>
												Vonder
											</a>{" "}
											is LRC Group’s Extended Stay Platform. It is a tech
											enabled, lifestyle-driven platform for extended stay,
											providing all-in-one living for digital nomads and young,
											transient professionals aged 20 to 40 years old seeking
											fully furnished living solutions enriched with a wealth of
											amenities and located within vibrant communities of
											like-minded individuals.
										</p>
									</div>
									<div className={styles.gridItems}>
										<img
											src={Vonder}
											alt='CommericalImage'
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	);
}
