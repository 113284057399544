import React from 'react';
import Logo from '../../../Assets/Logo/footer_svg.svg';
import styles from './Footer.module.scss';
import { NavLink } from 'react-router-dom';
import {
	FOOTER_BOTTOM_LINKS,
	MAIN_LINKS,
	SECONDARY_LINKS,
} from '../../../constants/links';
export default function Footer() {
	return (
		<div data-scroll-section>
			<footer>
				<div className='container'>
					<div className={styles.footerGrid}>
						<div className={styles.footerGridItems}>
							<img
								src={Logo}
								alt='Logo'
							/>
						</div>
						<div className={styles.footerGridItems}>
							<div className={styles.footerSubGrid}>
								<div className={styles.footerSubGridItems}>
									{/* <NavLink to='/urbanregeneration'>
										<p>Urban regeneration</p>
									</NavLink>
									<ul>
										{SECONDARY_LINKS.map(item => (
											<li key={item.href + 'footer'}>
												<NavLink to={item.href}>{item.title}</NavLink>
											</li>
										))}
									</ul> */}
								</div>
								<div className={styles.footerSubGridItems}>
									<p>Menu</p>

									<ul>
										{MAIN_LINKS.map(item => (
											<li key={item.href + 'footer'}>
												<NavLink to={item.href}>{item.title}</NavLink>
											</li>
										))}
									</ul>
								</div>

								<div className={styles.footerSubGridItems}>
									<p>social media</p>
									<ul>
										<li>
											<a
												href='https://www.linkedin.com/company/lrcgroup/'
												target='_blank'
												rel='noopener noreferrer'
											>
												LinkedIn
											</a>
										</li>
										{/* <li>Facebook</li>
                    <li>Instagram</li> */}
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div className={styles.footerChild}>
						<ul>
							{FOOTER_BOTTOM_LINKS.map(item => (
								<li key={item.href + 'footer'}>
									<a
										href={item.href}
										target='_blank'
										rel='noopener noreferrer'
									>
										{item.title}
									</a>
								</li>
							))}

							<li>© {new Date().getFullYear()} LRC Group.</li>
						</ul>
					</div>
				</div>
			</footer>
		</div>
	);
}
