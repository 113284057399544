export const mainLinksUrls = {
	about: '/aboutus',
	expertiseAndFund: '/expertise',
	esg: '/urbanregeneration',
	contact: '/contact',
};

export const MAIN_LINKS = [
	{
		title: 'About Us',
		href: mainLinksUrls.about,
	},
	{
		title: 'Expertise & Fund Solutions',
		href: mainLinksUrls.expertiseAndFund,
	},
	{
		title: 'ESG',
		href: mainLinksUrls.esg,
	},
	{
		title: 'Contact',
		href: mainLinksUrls.contact,
	},
];

export const secondaryLinks = {
	technology: '/technology',
	// sustainability: '/sustainability',
	scale: '/scale',
	community: '/community',
	wellbeing: '/wellbeing',
};

export const SECONDARY_LINKS = [
	{
		title: 'Technology',
		href: secondaryLinks.technology,
	},
	// {
	// 	title: 'Sustainability',
	// 	href: secondaryLinks.sustainability,
	// },
	{
		title: 'Scale',
		href: secondaryLinks.scale,
	},
	{
		title: 'Communities',
		href: secondaryLinks.community,
	},
	{
		title: 'Wellbeing',
		href: secondaryLinks.wellbeing,
	},
];

export const footerBottomLinks = {
	privacyPolicy: '/disclaimer-policy',
	sfdrDisclosure: '/sfdr-disclosure',
	paiStatement: '/pai-statement',
};

export const FOOTER_BOTTOM_LINKS = [
	{
		title: 'Privacy & Cookie Policy',
		href: footerBottomLinks.privacyPolicy,
	},
	{
		title: 'SFDR Disclosure',
		href: footerBottomLinks.sfdrDisclosure,
	},
	{
		title: 'PAI Statement',
		href: footerBottomLinks.paiStatement,
	},
];
