import logo from "./logo.svg";
import "./App.css";
import Home from "./components/home/Home";
import "./styles/mixins/global.scss";
import { Route, BrowserRouter as Router } from "react-router-dom";
import Header from "./components/Layout/Header";
import AboutUs from "./components/AboutUs/AboutUs";
import UrbanRegenration from "./components/UrbanRegenration/UrbanRegenration";
import Expertise from "./components/Expertise/Expertise";
import Contact from "./components/Contact/Contact";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CommunityPage from "./components/CommunityPage/CommunityPage";
import TechnologyPage from "./components/TechnologyPage/TechnologyPage";
import Wellbeing from "./components/Wellbeing/Wellbeing";
import Scale from "./components/Scale/Scale";
import NewsDetails from "./components/NewsDetails/NewsDetails";
import Disclaimer from "./components/Disclaimer/Disclaimer";
import Cookies from "./components/Cookies/Cookies";
import NewsAndInsights from "./components/NewsAndInsights/NewsAndInsights";
import Sfdr from "./components/SFDR/Sfdr";
import PAIStatement from "./components/PAI/Sfdr";
import { mainLinksUrls, secondaryLinks } from './constants/links'

function App() {
  return (
    <>
      <Router>
        {/* <Header/> */}
        <>
          <Cookies />
        </>
        <Route exact path="/" component={Home} />
        <Route exact path={mainLinksUrls.about} component={AboutUs} />
        <Route exact path={mainLinksUrls.esg} component={UrbanRegenration} />
        {/* <Route exact path={secondaryLinks.sustainability} component={Sustainability} /> */}
        <Route exact path={mainLinksUrls.expertiseAndFund} component={Expertise} />
        <Route exact path={mainLinksUrls.contact} component={Contact} />
        {/* <Route exact path={secondaryLinks.community} component={CommunityPage} /> */}
        {/* <Route exact path={secondaryLinks.technology} component={TechnologyPage} />
        <Route exact path={secondaryLinks.wellbeing} component={Wellbeing} />
        <Route exact path={secondaryLinks.scale} component={Scale} /> */}
        <Route exact path="/newsdetails" component={NewsDetails} />
        <Route exact path="/disclaimer-policy" component={Disclaimer} />
        <Route exact path="/news-insights" component={NewsAndInsights} />
        <Route exact path="/sfdr-disclosure" component={Sfdr} />
        <Route exact path="/pai-statement" component={PAIStatement} />
      </Router>
    </>
  );
}

export default App;
