import React, { useEffect, useState, useRef } from 'react';
import styles from './LrcGroups.module.scss';
import CombinedIcon from '../../../Assets/Images/Combined.svg';
import DiamondIcon from '../../../Assets/Images/Combined Shape.svg';
import EarthIcon from '../../../Assets/Images/earth.svg';
import DeployIcon from '../../../Assets/Images/diamond.svg';
import ChartIcon from '../../../Assets/Images/line-chart.svg';
import ContentIcon from '../../../Assets/Images/content.svg';
import MipimImage from '../../../Assets/Images/newshome1.png';
import HouseImage from '../../../Assets/Images/newshome2.png';
import DomeImage from '../../../Assets/Images/newshome3.png';
import classNames from 'classnames';
import useOnScreen from '../../../hooks/useOnScreen';
import { NavLink } from 'react-router-dom';
export default function LrcGroups() {
	const ref = useRef(null);

	const [reveal, setReveal] = useState(false);
	const onScreen = useOnScreen(ref);

	useEffect(() => {
		if (onScreen) setReveal(onScreen);
	}, [onScreen]);
	return (
		<div data-scroll-section>
			<div className={styles.benefitsScaleBaner}>
				<div className='container'>
					<div className={styles.bannerText}>
						<h1
							className='c-header_title o-h1'
							data-scroll
							data-scroll-speed='0.5'
							data-scroll-repeat
						>
							<span
								className={classNames('c-header_title_line', {
									'is-reveal': reveal,
								})}
							>
								<span
									data-scroll
									data-scroll-speed='1'
									data-scroll-repeat
									data-scroll-position='top'
								>
									Leveraging scale and technology
								</span>
								<span
									className='italic-class'
									data-scroll
									data-scroll-speed='1'
									data-scroll-position='top'
									data-scroll-repeat
								>
									to drive efficiency, enhance
								</span>
								<span
									data-scroll
									data-scroll-speed='1'
									data-scroll-position='top'
									data-scroll-repeat
								>
									asset performance, and
								</span>
								<span
									data-scroll
									data-scroll-speed='1'
									data-scroll-position='top'
									data-scroll-repeat
								>
									maximize investment returns.
								</span>
							</span>
						</h1>
					</div>
				</div>
			</div>

			<section className={styles.LrcGroupsAlignment}>
				<div className='container'>
					<div className={styles.grid}>
						<div className={styles.gridItems}>
							<div>
								<a>values</a>
								<h1
									className='c-header_title o-h1'
									data-scroll
									data-scroll-speed='0.5'
									data-scroll-repeat
								>
									<span
										className={classNames('c-header_title_line', {
											'is-reveal': reveal,
										})}
									>
										<span
											data-scroll
											data-scroll-speed='1'
											data-scroll-repeat
											data-scroll-position='top'
										>
											The LRC Group's
											<br />
										</span>
										<span
											className='italic-class'
											data-scroll
											data-scroll-speed='1'
											data-scroll-position='top'
											data-scroll-repeat
										>
											Core Principles & Values{' '}
										</span>
									</span>
								</h1>
								<div
									data-scroll
									data-scroll-speed='1'
								>
									<p>
										Thoughout the past 25 years, LRC has been committed to the
										same core values which have defined the Group’s goals and
										culture.
									</p>
								</div>
							</div>
						</div>
						<div className={styles.gridItems}>
							<div className={styles.subGrid}>
								<div className={styles.subGridItems}>
									<div className={styles.iconBoxDesign}>
										<img
											src={CombinedIcon}
											alt='CombinedIcon'
											className='flipImage'
											data-scroll
											data-scroll-repeat
										/>
									</div>
									<h1
										className='c-header_title o-h1'
										data-scroll
										data-scroll-speed='0.5'
										data-scroll-repeat
									>
										<span
											className={classNames('c-header_title_line', {
												'is-reveal': reveal,
											})}
										>
											<span
												data-scroll
												data-scroll-speed='1'
												data-scroll-repeat
												data-scroll-position='top'
											>
												Decades of expertise{' '}
											</span>
										</span>
									</h1>
									<p
										data-scroll
										data-scroll-speed='1'
									>
										We bring a combined experience of over 125 years in
										international property and finance markets to the table.
									</p>
								</div>
								<div className={styles.subGridItems}>
									<div className={styles.iconBoxDesign}>
										<img
											src={EarthIcon}
											alt='ContentIcon'
											className='flipImage'
											data-scroll
											data-scroll-repeat
										/>
									</div>

									<h1
										className='c-header_title o-h1'
										data-scroll
										data-scroll-speed='0.5'
										data-scroll-repeat
									>
										<span
											className={classNames('c-header_title_line', {
												'is-reveal': reveal,
											})}
										>
											<span
												data-scroll
												data-scroll-speed='1'
												data-scroll-repeat
												data-scroll-position='top'
											>
												Sustainability{' '}
											</span>
										</span>
									</h1>
									<p
										data-scroll
										data-scroll-speed='1'
									>
										LRC is committed to supporting sustainable practices that
										generate positive environmental and social impacts.
									</p>
								</div>

								<div className={styles.subGridItems}>
									<div className={styles.iconBoxDesign}>
										<img
											src={ContentIcon}
											alt='EarthIcon'
											className='flipImage'
											data-scroll
											data-scroll-repeat
										/>
									</div>

									<h1
										className='c-header_title o-h1'
										data-scroll
										data-scroll-speed='0.5'
										data-scroll-repeat
									>
										<span
											className={classNames('c-header_title_line', {
												'is-reveal': reveal,
											})}
										>
											<span
												data-scroll
												data-scroll-speed='1'
												data-scroll-repeat
												data-scroll-position='top'
											>
												Transparency{' '}
											</span>
										</span>
									</h1>
									<p
										data-scroll
										data-scroll-speed='1'
									>
										We believe integrity, authenticity and honesty are key for
										establishing long-term business practises and relationships.
									</p>
								</div>
								<div className={styles.subGridItems}>
									<div className={styles.iconBoxDesign}>
										<img
											src={DeployIcon}
											alt='DeployIcon'
											className='flipImage'
											data-scroll
											data-scroll-repeat
										/>
									</div>

									<h1
										className='c-header_title o-h1'
										data-scroll
										data-scroll-speed='0.5'
										data-scroll-repeat
									>
										<span
											className={classNames('c-header_title_line', {
												'is-reveal': reveal,
											})}
										>
											<span
												data-scroll
												data-scroll-speed='1'
												data-scroll-repeat
												data-scroll-position='top'
											>
												Innovative approach{' '}
											</span>
										</span>
									</h1>
									<p
										data-scroll
										data-scroll-speed='1'
									>
										We aim to always be ahead of the curve, utilising innovative
										technologies and forward thinking.
									</p>
								</div>
								<div className={styles.subGridItems}>
									<div className={styles.iconBoxDesign}>
										<img
											src={DiamondIcon}
											alt='DiamondIcon'
											className='flipImage'
											data-scroll
											data-scroll-repeat
										/>
									</div>

									<h1
										className='c-header_title o-h1'
										data-scroll
										data-scroll-speed='0.5'
										data-scroll-repeat
									>
										<span
											className={classNames('c-header_title_line', {
												'is-reveal': reveal,
											})}
										>
											<span
												data-scroll
												data-scroll-speed='1'
												data-scroll-repeat
												data-scroll-position='top'
											>
												Entrepreneurship{' '}
											</span>
										</span>
									</h1>
									<p
										data-scroll
										data-scroll-speed='1'
									>
										We take strategic decisions and use our team’s intuition and
										skillful capabilities to seize opportunities.
									</p>
								</div>
								<div className={styles.subGridItems}>
									<div className={styles.iconBoxDesign}>
										<img
											src={ChartIcon}
											alt='ChartIcon'
											className='flipImage'
											data-scroll
											data-scroll-repeat
										/>
									</div>

									<h1
										className='c-header_title o-h1'
										data-scroll
										data-scroll-speed='0.5'
										data-scroll-repeat
									>
										<span
											className={classNames('c-header_title_line', {
												'is-reveal': reveal,
											})}
										>
											<span
												data-scroll
												data-scroll-speed='1'
												data-scroll-repeat
												data-scroll-position='top'
											>
												Impact investing{' '}
											</span>
										</span>
									</h1>
									<p
										data-scroll
										data-scroll-speed='1'
									>
										We strive to create responsible business decisions in order
										to drive positive impact on communities.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}
